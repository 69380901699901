import React from "react"
import App from "../components/App"
import Cards from '../components/Cards';

export default function NotFound() {
  return (
    <App>

      <div className='right-frame'>
		<p>Page that you are looking for seems to have moved. Please select from the following topics:</p>
        <Cards />
      </div>

    </App>
  )  
}